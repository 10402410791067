/* Sidebar Styles */
.sidebar {
    width: 220px;
    background: rgb(0 0 0); 
    padding: 20px;
    
    height: 100%;
    color: white; /* White text color */
    box-shadow: 3px 0 15px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  }
  
  .sidebar-menu {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar-menu li {
    margin-bottom: 20px;
  }
  
  .sidebar-menu li a {
    color: white;
    text-decoration: none;
    font-size: 14px; /* Slightly bigger font */
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px; /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover transitions */
  }
  
  .sidebar-menu li a:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle hover background */
    transform: translateX(5px); /* Slight movement on hover */
  }
  
  .sidebar-menu li.active a {
    background-color: rgba(255, 255, 255, 0.3); /* Highlight active link */
    font-weight: bold;
  }
  
  .menu-icon {
    margin-right: 15px;
    font-size: 22px; /* Slightly larger icons */
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .sidebar {
      width: 60px;
    }
  
    .menu-icon {
      margin-right: 0;
    }
  
    .sidebar-menu li a {
      justify-content: center;
    }
  
    .sidebar-menu li a span {
      display: none; /* Hide text on smaller screens */
    }
  }
  
