/* Sidebar Styles */

/* Order Content */
.order-content {
  padding: 40px;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.order-header h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.order-tabs button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin-right: 15px;
  cursor: pointer;
}

.order-tabs button:hover {
  color: #e74c3c;
}

.search-bar {
  display: flex;
}

.search-bar input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
  height: 35px;
}


.searchbutton{
  padding: 8px 12px;
  border: none;
  background-color: #ff7004;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 10px;
}
.resetbutton{
  padding: 8px 12px;
  border: none;
  background-color: #2ecc71;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}

/* Table Styling */
.tableorderhistory {
  width: 100%;
  border-collapse: collapse;
}


.tableorderhistory .cancel-btn,
.tableorderhistory .view-order-btn {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
}

.tableorderhistory .cancel-btn {
  background-color: #e74c3c;
  color: white;
}

.tableorderhistory .view-order-btn {
  background-color: #ff7004;
  color: white;
}

.tableorderhistory .cancel-btn:hover {
  background-color: #c0392b;
}

.tableorderhistory .view-order-btn:hover {
  background-color: #2980b9;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: #ddd;
  cursor: pointer;
}

.pagination button.active {
  background-color: #ff7004;
  color: white;
}
.pagination button:hover {
  background-color: #ff7004;
  color: white;
}


/* Table Styling */
.tableorderhistory {
  width: 100%;
  border-collapse: collapse;
}

.tableorderhistory th,
.tableorderhistory td {
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
}

.tableorderhistory th {
  font-weight: bold;
  color: #333;
}
.tableorderhistory thead {
  background-color: #f7f7f7;
  border: 2px solid #e1e1e1;
  border-radius: 50px;
}

.tableorderhistory td {
  border-bottom: 1px solid #eee;
  color: #555;
}

/* Remove borders */
.tableorderhistory td,
.tableorderhistory th {
  border: none;
}

.tableorderhistory tr{
  border-bottom: 1px solid #e1e1e1;
}

/* Status Colors */
.tableorderhistory .status.new_order {
  background-color: #7c6bff;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
}

.tableorderhistory .status.processing {
  background-color: #f39c12;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
}

.tableorderhistory .status.delivered {
  background-color: #2ecc71;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
}

.tableorderhistory .status.cancelled {
  background-color: red;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
}


/* Action Icons */
.cancel-icon
{
  cursor: pointer;
  font-size: 22px;
  margin-right: 10px;
  color: red;
}

.view-order-icon {
  cursor: pointer;
  font-size: 22px;
  margin-right: 10px;
  color: #2ecc71;
}

.cancel-icon:hover {
  color: #e74c3c;
}

.view-order-icon:hover {
  color: #ff7004;
}

/* Active Tab */
.order-tabs button.active {
  color: #ff7004;
  font-weight: bold;
  border-bottom: 2px solid #ff7004;
}

/* Custom tooltip styles */
.__react_component_tooltip {
  font-size: 12px;
  padding: 8px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}



 /* Specific Order Details*/

  /* Order Details Page Styles */

  /* Order Details Page Styles */

.order-details-container {
  padding: 20px;
}

.order-header {
  margin-bottom: 30px;
}

.order-header h2 {
  font-size: 26px;
  font-weight: bold;
  color: #2c3e50;
}

.order-status-badge {
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.info-section-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.info-section {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.info-section:hover {
  transform: translateY(-3px); /* Adds a slight lift on hover */
}

.info-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 2px solid #f39c12;
  padding-bottom: 5px;
}

.info-section p {
  font-size: 15px;
  color: #7f8c8d;
  margin: 8px 0;
}

.order-items-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

.order-items-table th,
.order-items-table td {
  border: 1px solid #ddd;
  padding: 14px;
  text-align: left;
  font-size: 16px;
}

.order-items-table th {
  background-color: #f7f7f7;
  color: #2c3e50;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.order-items-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Light row color */
}

.order-items-table td img {
  max-width: 80px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.order-total {
  margin-top: 10px;
  text-align: right;
  font-size: 22px;
  font-weight: bold;
  color: #2c3e50;
}

.order-total p {
  font-size: 24px;
  color: #e74c3c;
}

.order-top-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-top-section h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #34495e;
}

.order-dates {
  font-size: 14px;
  color: #7f8c8d;
}

.order-dates span {
  margin-right: 10px;
}

.order-actions {
  display: flex;
  gap: 10px;
}

.action-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.more-btn {
  background-color: #f1c40f;
  color: white;
}

.more-btn:hover {
  background-color: #f39c12;
}

.export-btn {
  background-color: #3498db;
  color: white;
}

.export-btn:hover {
  background-color: #2980b9;
}

.create-label-btn {
  background-color: #27ae60;
  color: white;
}

.create-label-btn:hover {
  background-color: #2ecc71;
}

/* Status Colors */
.order-status-badge.new_order {
  background-color: #7c6bff; /* Purple */
  color: white;
}

.order-status-badge.processing {
  background-color: #f39c12; /* Orange */
  color: white;
}

.order-status-badge.delivered {
  background-color: #2ecc71; /* Green */
  color: white;
}

.order-status-badge.cancelled {
  background-color: red; /* Red */
  color: white;
}

/* Order Status Badge */
.order-status-badge {
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
}

